<template>
  <div class="w">
    <!-- <p v-for="item in 100">{{ item }}</p> -->
    <!-- <el-collapse  v-model="activeNames" @change="handleChange">
            <el-collapse-item title="章节列表" name="1">
              <div class="chapter" v-for="item in content.bookChapterList" :key="item.chapterId">
                <span class="es-1">{{item.chapterName}} </span>
                <el-button type="primary" size="mini" @click="toRead(item.bookId,item.chapterId,content.title)" round>直达章节</el-button>
              </div>  
            </el-collapse-item>
          </el-collapse> -->

    <div class="">

    </div>
    <el-card class="box-card">
      <div slot="header" >
        <span style="line-height: 35px; font-weight: bold">电子书详情</span>
        <div style="float: right; padding: 3px 0">
          <el-button style="margin-right: 10px" :type="isCollect?'warning':'success'" :icon="!isCollect?'el-icon-star-off':'el-icon-star-on'" size="mini" round @click="collcet">{{isCollect?'已收藏':'收藏' }}</el-button>
          <router-link v-if="content.bookChapterList"  :to="{ name: 'read', query: { id:content.bookId, chapter:content.bookChapterList[0].chapterId,name:content.title } }">
            <el-button type="primary" icon="el-icon-reading" size="mini" round>开始阅读</el-button>
          </router-link>
        </div>
      </div>
      <div class="book">
        <div class="img">
          <img :src="content.titleImg" alt="" />
        </div>
        <div class="book-info">
          <ul>
            <li class="title es-2">
              {{content.title}}
            </li>
            <li class="author">作者:{{content.author}}</li>
            <li class="publish">出版社:{{content.publish?content.publish:'暂无'}}</li>
            
            <li class="content">
              简介:{{content.description}}
            </li>
          </ul>
            <div>
             
            </div>
          <!-- <el-collapse  v-model="activeNames" @change="handleChange">
            <el-collapse-item title="章节列表" name="1">
              <div class="chapter" v-for="item in content.bookChapterList" :key="item.chapterId">
                <span class="es-1">{{item.chapterName}} </span>
                <el-button type="primary" size="mini" @click="toRead(item.bookId,item.chapterId,content.title)" round>直达章节</el-button>
              </div>  
            </el-collapse-item>
          </el-collapse> -->
        </div>
      </div>
   
    </el-card>
     <el-collapse style="padding: 30px;box-shadow: 0 0 10px rgba(0, 0, 0, .1);"  v-model="activeNames" @change="handleChange">
        <el-collapse-item title="章节列表" name="1">
          <div class="chapter" v-for="item in content.bookChapterList" :key="item.chapterId">
            <span class="es-1">{{item.chapterName}} </span>
            <el-button type="primary" size="mini" @click="toRead(item.bookId,item.chapterId,content.title)" round>直达章节</el-button>
          </div>  
        </el-collapse-item>
      </el-collapse>
  </div>
</template>

<script>
import { getBook,getCollect,addCollect} from "@/api";
export default {
  name: "detail",
  data() {
    return {
      activeNames: ['1'],
      content:{},
      isCollect:false
    };
  },
  methods: {
    handleChange() { },
  },
  created() {

    document.body.scrollTop =0
    this.getBookDetail()
    if(this.Storage.get('token')){
      // console.log(111);
     this.getCollectStatus()
    }
  },
  methods: {
    collcet(){
      if(!this.Storage.get('token')){
        this.$message.warning({message:'您还没有登录，去登录!'})
        setTimeout(()=>{
          this.$router.push({path:'/login'})
        },500)  
      }else{
        addCollect(this.$route.params.id).then(res=>{
          this.$message.success({message:!this.isCollect?'收藏成功!':'取消收藏成功!'})
          this.getCollectStatus()
        })
      }
    },
    getCollectStatus(){
      getCollect(this.$route.params.id).then(res=>{
        if(res.code === 200 ){
          this.isCollect=res.data.collection
        }
      })
    },
    toRead(bookId,chapterId,name){
      // console.log(name);
      // 外部跳转
      // let routeData = this.$router.resolve({ path: '/read', query: {  id: bookId,chapter:chapterId ,name} });
      // window.open(routeData.href, '_blank');

      // 内部跳转
      this.$router.push({ path: '/read', query: {  id: bookId,chapter:chapterId ,name} })

    },
   getBookDetail(){
    getBook(this.$route.params.id).then(res=>{
      if(res.code === 200){
        this.content=res.data
      }
    })
   },
   handleChange(){}
  },
};
</script>

<style lang="scss" scoped>
.chapter{
      display: flex;
      padding: 10px 0;
      border-bottom: 1px dashed #ccc;
      justify-content: space-between;
      span {
        // width: 500px;
        // line-height: 50px
      };
    }
.book {
  display: flex;
  justify-content: space-between;

  .img {
    width: 40%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    img {
      width: 300px;
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    }
  }

  .book-info {
    width: 60%;
    padding: 30px;
    
    // background-color: #999;
    li {
      // font-size: 20px;
      line-height: 40px;
    }

    .title {
      font-size: 25px;
      font-weight: bold;
    }
  }
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  // width: 480px;
}
</style>